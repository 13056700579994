<!-- 产品列表 -->

<template>
  <div>
    <el-row>
      <el-col :span="24" class="box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/Projucts' }">产品</el-breadcrumb-item>
          <el-breadcrumb-item>{{ NavId.Name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <!-- 主区域 -->
    <el-row style="min-height: 600px;">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-for="one in OneTypes" :key="one.id" class="box  animate__animated animate__fadeInUpBig">
        <div class="container">
          <div class="image projuctIMG">
            <img v-if="one.urls" :src="JSON.parse(one.urls).images[0]" alt="" style="object-fit:cover;" @click="toDetail(one)">
          </div>
          <div class="content">
            <div class="content-inner">
              <el-row justify="space-between">
                <el-col :span="24" style="text-align: left;" class="name">{{ one.name }}</el-col>
                <el-col :span="10" style="text-align: left;" class="model">{{ one.model }}</el-col>
                <el-col :span="14" style="text-align: right;" class="model">{{ one.size }}</el-col>
                <el-col :span="24" class="model">
                  <button class="details-btn" @click="toDetail(one)">查看详情</button>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ArrowRight } from '@element-plus/icons-vue';
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getOneType } from '../api/apis';
import { NavId } from '../store/store';

const props = defineProps({
  message: {
    type: String,
    require: true,
    default: "默认值"
  },
});
const sss = ref("sas")

const route = useRoute();
// 获取产品类型ID
const ProjuctsTypeName = ref(NavId.Name)

// 获取某类型产品
const OneTypes = ref()
const getOneTypes = async (id) => {
  try {
    const result = await getOneType(id);
    OneTypes.value = result.data;
    // console.log(id,JSON.parse(OneTypes.value[0].urls))
  } catch (error) {
    console.error(error);
  }
}
// 跳转详情页
const router = useRouter();
const toDetail = (info) => {
  router.push({ name: 'Detail', query: { ID: info.id } });
}
const what = () => {
  // console.log(ProjuctsTypeName.value)
}
const IDD = ref(NavId.ID)
onBeforeMount(() => {
  getOneTypes(IDD.value)
  // ProjuctsTypeName.value = route.query.name
})

watch(() => NavId.ID,
  (newId) => {
    IDD.value = newId ? newId : 1000
    getOneTypes(IDD.value)
  },
  { immediate: true }
)
watch(() => route.query.name, (newValue) => {
  ProjuctsTypeName.value = newValue
});
</script>

<style lang="scss" scoped>
.box {
  padding: 1.2em 2.2em;
  box-sizing: border-box;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 450px;
  overflow: hidden;
  position: relative;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.3s ease;
}

.container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.8);
}

.image {
  width: 100%;
  height: 350px;
}

.content {
  width: 100%;
  height: 150px;
  background: white;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.content-inner {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
  background-color: brown;
  color: aliceblue;
  .name {
    font-size: x-large;
    font-weight: 600;
    padding: 10px 0;
  }
  .model {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 0;
  }
}

.details-btn {
  width: 100px;
  height: 30px;
  background-color:#ffffff;
  color: #a52a2a;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-btn:hover {
  background-color: #d8d8d8;
}

.content:hover {
  transform: translateY(-50px);
}

.projuctIMG {
  overflow: hidden;
  height: 350px;
  position: relative;
}

/*为img添加放大效果*/
.projuctIMG:hover img {
  transform: translate(-50%, -50%) scale(1.1);
  /* 悬停时放大图片 */
}

/*为img添加过渡效果*/
.projuctIMG img {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  /* 图片宽度不超过容器 */
  max-height: 100%;
  /* 图片高度不超过容器 */
  transition: transform 0.3s ease;
  /* 添加过渡动画 */
}

/*转换为块级元素，宽度100%*/
img {
  display: block;
  height: 100%;
}

@media (max-width: 768px) {
  .box {
    padding: 1.8em 4.8em;
  }
}
</style>
