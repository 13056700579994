<template>
  <div class="scroll-container" ref="scrollContainer" @mouseover="pauseScrolling" @mouseleave="resumeScrolling">
    <ul class="scroll-list" ref="scrollList">
      <li v-for="item in virtualItems" :key="item.id" class="li" @click="handleClick(item.id)"
        @mouseover="highlightItem($event)" @mouseleave="unhighlightItem($event)"
        :class="{ 'highlight': highlightedItem === item.id }" :data-id="item.id">
        <el-row>
          <el-col :span="14" class="title">{{ item.title }}</el-col>
          <el-col :span="10" class="time">{{ item.time }}</el-col>
        </el-row>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { getAllTrueArcitle } from '../api/apis';
import { useRouter } from 'vue-router'

const router = useRouter();
const items = ref([]);
const virtualItems = ref([]);
const scrollContainer = ref(null);
const scrollList = ref(null);
const highlightedItem = ref(null);
let scrollInterval;

const getAllTrueArcitles = async () => {
  const res = await getAllTrueArcitle();
  items.value = res.data;
  // 将列表项复制几次，以确保总数足够进行无缝滚动
  for (let i = 0; i < 4; i++) {
    virtualItems.value.push(...res.data.map(item => ({ ...item, id: `${item.id}-${i}` })));
  }
};

const startScrolling = () => {
  const scrollHeight = scrollList.value.scrollHeight / 2;

  const scroll = () => {
    if (scrollContainer.value.scrollTop >= scrollHeight) {
      scrollContainer.value.scrollTop = 0;
    } else {
      scrollContainer.value.scrollTop += 1;
    }
  };

  scrollInterval = setInterval(scroll, 30);
};

const pauseScrolling = () => {
  clearInterval(scrollInterval);
};

const resumeScrolling = () => {
  startScrolling();
};
// 跳转详情
const handleClick = (id) => {
  

  const originalId = id.split('-')[0];
    sessionStorage.setItem('ArticleId', originalId)
    router.push({ path: '/Article' })
  // console.log(originalId);
};

const highlightItem = (event) => {
  highlightedItem.value = event.currentTarget.dataset.id;
};

const unhighlightItem = (event) => {
  if (highlightedItem.value === event.currentTarget.dataset.id) {
    highlightedItem.value = null;
  }
};

onMounted(async () => {
  await getAllTrueArcitles();
  await nextTick();
  startScrolling();
});

onBeforeUnmount(() => {
  clearInterval(scrollInterval);
});
</script>

<style lang="scss" scoped>
.scroll-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.scroll-list {
  margin: 0;
  padding: 0 10px;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f0f4f8;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;

  .li {
    margin: 5px 0;
  }

  .highlight {
    // background-color: #e0f7fa;
    background-color: #3b5998;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: #3b5998;
    color: #fff;
  }

  .time {
    text-align: right;
    color: #666;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.scroll-list li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color: #333;
}
</style>
