<!-- 页头 -->


<template>
  <el-row class="nav-bar">
    <!-- 左侧LOGO -->
    <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="logo animate__animated animate__fadeInLeft"
      style="text-align: center;">
      山西卓立佰电气
    </el-col>
    <div v-if="isMobile" class="mobileSub" style="" @click="drawer = true">☰</div>
    <!-- 右侧导航按钮 -->
    <el-col :span="20" class="nav-buttons  animate__animated animate__fadeInRight" v-if="!isMobile">
      <div v-for="nav in Navs" :key="nav.id" class="nav-button" @mouseenter="showPanel(nav)"
        @mouseleave="hidePanelWithDelay">
        <!-- <router-link :to="nav.router" active-class="nav-active" exact-active-class="nav-exact-active" class="nav-link1"
          @click="setNavIDName(nav)">{{ nav.label }}</router-link> -->
        <a :class="{ 'parentActive': nav.id == NavId.ParentID }" active-class="nav-active"
          exact-active-class="nav-exact-active" class="nav-link1" @click="toNew(nav)">{{ nav.label }}</a>
          
      </div>
      <div class="nav-button"><a class="nav-link1" href="https://ywystation.cn">English</a></div>

      
    </el-col>

    <!-- 面板 -->
    <transition name="fade">
      <el-row v-if="isPanelVisible && panelContent.children.length" class="panel" @mouseenter="keepPanelVisible"
        @mouseleave="hidePanelWithDelay">
        <el-col :span="18" class="label">
          {{ panelContent.label }}
        </el-col>
        <el-col :span="18" class="title">
          {{ panelContent.title }}
        </el-col>
        <el-col :span="18">
          <el-row>
            <el-col :span="8" style="height: 180px;"><img :src="panelContent.url" alt="" style="height: 100%;"></el-col>
            <el-col :span="16" class="parent">

              <div v-for="(button, index) in panelContent.children" class="child" :key="button.id"
                :class="{ 'active': button.id === NavId.ID }">
                <div class="nav-link2" @click="toNew(button, index)">{{ button.label }} </div>

              </div>

            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </transition>
    <!--面板结束  -->
    <!-- 手机版侧边栏 -->
    <el-drawer v-model="drawer" title="I am the title" :with-header="false" size="80%" :z-index="70" accordion style="z-index: 75 !important;">
      <div>
        <!-- <div>
          <el-button type="success" plain size="large">{{ nav.label }}</el-button>
        </div> -->
        <!-- 更简洁的版本 -->
        <el-menu :default-active="NavId.ID.toString()" class="el-menu-vertical-demo">
          <template v-for="item in Navs" style="border:none">
            <!-- 判断是否有子菜单 -->
            <el-sub-menu v-if="item.children && item.children.length" :key="item.id" :index="item.id.toString()">
              <template #title>
                <span >{{ item.label }}</span>
              </template>
              <!-- 渲染子菜单 -->
              <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.id.toString()">
                <span  @click="toNew(subItem)" style="width: 100%;">{{ subItem.label }}</span>
              </el-menu-item>
            </el-sub-menu>
            <!-- 渲染没有子菜单的菜单项 -->
            <el-menu-item v-else  :index="item.id.toString()">
              <span  @click="toNew(item)">{{ item.label }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </el-drawer>
  </el-row>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
// import api from '../api/apis'; // 导入api.js
import { getNav } from '../api/apis'
import { NavId } from '../store/store'


// 计算属性生成 index



const drawer = ref(false)

const Navs = ref([]);
const getNavs = async () => {
  try {
    const result = await getNav();
    Navs.value = result.data;
    // console.log(Navs.value)
  } catch (error) {
    console.error(error);
  }
};
// 面板相关
const isPanelVisible = ref(false);
const panelContent = ref(null);
let hidePanelTimeout = null;

const showPanel = (nav) => {
  panelContent.value = nav;
  isPanelVisible.value = true;
  clearHidePanelTimeout();
};

const hidePanelWithDelay = () => {
  hidePanelTimeout = setTimeout(() => {
    isPanelVisible.value = false;
    panelContent.value = null;
  }, 100);
};

const keepPanelVisible = () => {
  clearHidePanelTimeout();
};

const clearHidePanelTimeout = () => {
  if (hidePanelTimeout) {
    clearTimeout(hidePanelTimeout);
    hidePanelTimeout = null;
  }
};
// 跳转页面
const router = useRouter();
const toNew = (info) => {
  setNavIDName(info)
  drawer.value=false
  router.push(info.router);
}
// 设置ID
const setNavIDName = (info) => {
  if (info.children != null && info.children.length > 0) {
    // console.log("正在设置子路由信息", info)
    NavId.setRoute(info.children[0].routerName)
    NavId.setID(info.children[0].id)
    NavId.setName(info.children[0].label)
    NavId.setParentID(info.id)
    NavId.setParentName(info.label)
  } else {
    NavId.setRoute(info.routerName)
    // console.log("正在设置主路由信息", info)
    NavId.setID(info.id)
    NavId.setName(info.label)

    if (info.belongs !== null) {
      NavId.setParentID(info.belongs)
      NavId.setParentName(info.belongsName)
    } else {
      NavId.setParentID(info.id)
      NavId.setParentName(info.label)
    }
  }
  // 定义一个定时器，在2毫秒后进行路由跳转

}
// 面板结束
const isMobile = ref(window.innerWidth <= 768);
const isMobileMenuOpen = ref(false);
// 窗口尺寸
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  if (isMobile.value) {
    // console.log("现在是手机")
    // activePanel.value = null;
    // panelVisible.value = false;
  }
};



// 向下色深改变
const isTransparent = ref(false);
function ScrollUpDown() {
  isTransparent.value = window.pageYOffset > 0;
}
onBeforeMount(() => {
  getNavs();
})
onMounted(() => {
  window.addEventListener('resize', handleResize);
  window.addEventListener('scroll', ScrollUpDown);
});

onUnmounted(() => {
  // console.log("注销")
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('scroll', ScrollUpDown);
});
</script>

<style scoped>
* {
  margin: 0;
}

.nav-active {
  color: #3498db;
  /* 一级导航的选中样式 */
}

.nav-exact-active {
  font-weight: bold;
  /* 一级导航完全匹配时的样式 */
}

.sub-nav-active {
  color: #db3434;
  /* 二级导航的选中样式 */
  border-bottom: #db3434 2px solid;
  border-top: 2px solid #db3434;
}

.sub-nav-exact-active {
  text-decoration: underline;
  /* 二级导航完全匹配时的样式 */
}

.parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.child {
  /* 你可以添加一些样式，如宽度、边框等 */
  position: relative;
  flex: 0 0 auto;
  width: calc(33.33% - 10px);
  /* 如果你想让每个子元素占据大约1/3的宽度，并减去一些间隔 */
  height: 45px;
  background: #e4e4e4b7;
  margin: 5px;
  /* 添加一些间隔 */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  box-sizing: border-box;
  overflow: hidden;
  /* 以防伪元素在动画过程中溢出 */
}

.child::before,
.child::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #db3434;
  transition: width 0.5s;
}

.child::before {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.child::after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.child:hover::before,
.child:hover::after {
  width: 100%;
}


a {
  /* height: 50px; */
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}



.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;
  background-color: #2c3e50;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  color: white;
  /* text-align: center; */
}


.nav-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
}

.nav-button {
  /* margin-right: 20px; */
  /* cursor: pointer; */
  padding: 12px 10px;
}

/* .nav-button:last-child {
  margin-right: 0;
} */

.nav-link1 {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s;
}

.nav-link1:hover {
  color: #3498db;
}

.nav-link2 {
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;
}

.nav-link2:hover {
  color: #db3434;
}



.active {
  color: #db3434;
  border-bottom: #db3434 2px solid;
  border-top: 2px solid #db3434;
}

.parentActive {
  color: #3498db;
}

.mobileSub {
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 10;
  color: aliceblue;
  right: 10px;
  top: 9px;
  font-size: 2em;

}

.panel {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  z-index: 9;
  /* 小于导航栏，确保不遮挡导航栏 */

}

.label {
  font-size: 1.6em;
  font-weight: 600;
}

/* .title {} */

.panel h3 {
  margin: 0;
  color: #2c3e50;
}

.panel p {
  color: #7f8c8d;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
