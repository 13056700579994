// api.js
import http from './http'; // 引入 Axios 实例




// 获取轮播图

export const getCarousel = () => http.get('/Front/getCarousel');
// 获取导航栏
export const getNav = () => http.get('/Front/getNav');
// 获取所有类型
export const getAllType = () => http.get('/Front/getAllType');
// 获取所有产品
export const getAllAchievements = (id) => http.get('/Front/getAllAchievements/'+id);
// 获取单个类型
export const getOneType = (id) => http.get('/Front/getOneType/' + id);
// 获取单个产品
export const getOne = (id) => http.get('/Front/getOne/' + id);
// 获取使用说明
export const getInstructions = (id) => http.get('/Front/getInstructions/' + id);
// 获取公司资质
export const getCompanyZi = () => http.get('/Front/getCompanyZi');

// 获取一类可查看文章
export const getOneTypeArticle = (id) => http.get('/Front/getOneTypeArticle/' + id);

// 获取一篇文章
export const getOneArticle = (id) => http.get('/Front/getOneArticle/' + id);
// 新增留言
export const addMessage = (message) => http.post('/Front/addMessage', message);
// 获取公司信息
export const getCompanyInfo = () => http.get('/Front/getCompanyInfo');

// 获取行业新闻
export const getIndustryNews = () => http.get('/Front/getIndustryNews');
// 获取业绩新闻
export const getAllAchievementArcitle = () => http.get('/Front/getAllAchievementArcitle');
// 获取所有在线新闻
export const getAllTrueArcitle = () => http.get('/Front/getAllTrueArcitle');




export const createUser = (user) => http.post('/users', user);
export const updateUser = (id, user) => http.put(`/users/${id}`, user);
export const deleteUser = (id) => http.delete(`/users/${id}`);