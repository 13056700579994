<template>
    <div>
      <div class="customer-service-button" @click="toggleInfoPanel">客服<br/>热线</div>
      <transition name="fade">
        <div v-show="showInfoPanel" class="info-panel">
          <h3>客服热线</h3>
          <p>电话: {{companyInfo.tel}}</p>
          <p>邮箱: {{companyInfo.email}}</p>
        </div>
      </transition>
    </div>
  </template>
  
  <script setup>
  import {ref, onMounted} from 'vue'
import {getCompanyInfo} from '../api/apis'

const companyInfo = ref({})

onMounted(() => {
    getCompanyInfo().then(res => {
        companyInfo.value = res.data
    })
})
  
  const showInfoPanel = ref(false);
  
  const toggleInfoPanel = () => {
    showInfoPanel.value = !showInfoPanel.value;
  };
  </script>
  
  <style scoped>
  .customer-service-button {
    position: fixed;
    right: 20px;
    bottom: 180px;
    background-color: rgb(255, 255, 255);
    color: rgb(64, 158, 255);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    z-index: 60;
  }
  
  .customer-service-button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .info-panel {
    position: fixed;
    right: 20px;
    bottom: 250px;
    width: 250px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 59;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .fade-enter-to, .fade-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
  </style>
  