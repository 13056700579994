import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css'
import Viewer from 'v-viewer'; // 确保导入 v-viewer
import 'viewerjs/dist/viewer.css'; // 确保引入样式

const app = createApp(App)
app.use(Viewer)
app.use(VueAwesomeSwiper)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.mount('#app')
