

<!-- 产品图放大 -->


<template>
    <div class="goods-image">
        <!-- 大图 -->
        <div v-show="show" class="large" :style="[{ backgroundImage: `url(${images[currIndex]})`}, largePosition]">
        </div>
        <!-- 中图 -->
        <div class="middle" ref="target">
            <img :src="images[currIndex]" alt="">
            <!-- 遮罩色块 -->
            <div v-show="show" class="layer" :style="layerPosition"></div>
        </div>
        <!-- 小图 -->
        <div class="carousel-container">
            <button class="carousel-button left-button" @click="scrollLeft" :disabled="isFirstImage">←</button>
            <div class="carousel-viewport">
                <div class="carousel-track" :style="{ transform: `translateX(-${currentIndex * 100}px)` }">
                    <img v-for="(image, index) in images" :key="index" :src="image" alt="Carousel Image"
                        @mouseenter="currIndex = index" :class="{ active: index === currIndex }">
                </div>
            </div>
            <button class="carousel-button right-button" @click="scrollRight" :disabled="isLastImage">→</button>
        </div>

    </div>
</template>
<script>
import { reactive, ref, watch, computed } from 'vue'
import { useMouseInElement } from '@vueuse/core'
export default {
    name: 'HelloWorld',
    props: {
        images: {
            type: Array,
            default: () => ['https://yanxuan-item.nosdn.127.net/a6939f41c48fa9e9c8f7a7ed855351f1.jpg',
                'https://yanxuan-item.nosdn.127.net/0cdfd546f8675669b87716114ad5900a.jpg',
                'https://yanxuan-item.nosdn.127.net/240983ccc935146a4795e3990d30468d.jpg',
                'https://yanxuan-item.nosdn.127.net/d46e005025a5d3b73c4781d31b327558.jpg',
                'https://yanxuan-item.nosdn.127.net/330913911087b44b0d817dd78233165f.png',]
        }
    },
    setup(props) {
        // 当前预览图的索引
        const currIndex = ref(0)

        // 1. 是否显示遮罩和大图
        const show = ref(false)
        // 2. 遮罩的坐标(样式)
        const layerPosition = reactive({
            left: 0,
            top: 0
        })
        // 3. 大图背景定位(样式)
        const largePosition = reactive({
            backgroundPositionX: 0,
            backgroundPositionY: 0
        })





        const currentIndex = ref(0);

        // 计算总图片数量和每次显示的图片数量
        const totalImages = props.images.length;
        const visibleImages = 3;

        const isFirstImage = computed(() => {
            return currentIndex.value === 0;
        });

        const isLastImage = computed(() => {
            return currentIndex.value >= totalImages - visibleImages;
        });

        const scrollLeft = () => {
            if (currentIndex.value > 0) {
                currentIndex.value--;
            }
        };

        const scrollRight = () => {
            if (currentIndex.value < totalImages - visibleImages) {
                currentIndex.value++;
            }
        };




        // 4. 使用useMouseInElement得到基于元素左上角的坐标和是否离开元素数据
        const target = ref(null)
        const { elementX, elementY, isOutside } = useMouseInElement(target)
        watch([elementX, elementY, isOutside], () => {
            // 5. 根据得到数据设置样式数据和是否显示数据
            show.value = !isOutside.value
            // 计算坐标
            const position = { x: 0, y: 0 }

            if (elementX.value < 100) position.x = 0
            else if (elementX.value > 300) position.x = 200
            else position.x = elementX.value - 100

            if (elementY.value < 100) position.y = 0
            else if (elementY.value > 300) position.y = 200
            else position.y = elementY.value - 100
            // 给样式赋值
            layerPosition.left = position.x + 'px'
            layerPosition.top = position.y + 'px'
            largePosition.backgroundPositionX = -2 * position.x + 'px'
            largePosition.backgroundPositionY = -2 * position.y + 'px'
        })

        return { currIndex, show, layerPosition, largePosition, target, currentIndex, visibleImages, scrollLeft, scrollRight, isFirstImage, isLastImage }
    }
}
</script>
<style scoped lang="scss">
.goods-image {
    width: 400px;
    // height: 400px;
    position: relative;
    // display: flex;
    // z-index: 500;

    .large {
        position: absolute;
        top: 0;
        left: 412px;
        width: 400px;
        height: 400px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-repeat: no-repeat;
        background-size: 800px 800px;
        background-color: #f8f8f8;
        z-index: 10;
        img{
            width: 100% !important;
        }
    }

    .middle {
        width: 400px;
        height: 400px;
        background: #f5f5f5;
        // position: relative;
        cursor: move;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        //cursor: pointer;
        .layer {
            width: 200px;
            height: 200px;
            background: rgba(0, 0, 0, .2);
            left: 0;
            top: 0;
            position: absolute;
        }
    }

}

.carousel-container {
    display: flex;
    align-items: center;
    width: 400px;
    height: 100px;
    overflow: hidden;
    // position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.carousel-viewport {
    overflow: hidden;
    width: 300px;
    /* 3 images * 100px width */
    height: 100px;
}

.carousel-track {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-track img {
    min-width: 100px;
    max-width: 100px;;
    height: 100px;
    padding: 1px;
    object-fit: contain;

    &:hover,
    &.active {
        border: 2px solid #27BA9B;
    }
}

.carousel-button {
    width: 50px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s;
}

.carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.left-button {
    left: 0;
}

.right-button {
    right: 0;
}

.left-button:disabled,
.right-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
</style>