import { createRouter, createWebHistory } from 'vue-router'
import ContentList from '../components/ContentList.vue'
import Detail from '../components/Detail.vue'
import Achievements from '../views/Achievements.vue'
import IndexView from '../views/IndexView.vue'
import NewView from '../views/NewView.vue'
import ProjuctsView from '../views/ProjuctsView.vue'
import TechnologyView from '../views/TechnologyView.vue'
import About from '../views/AboutView.vue'
import CallUs from '../components/CallUs.vue'
import AboutImage from '../components/AboutImage.vue'
import AchievementList from '../components/AchievementList.vue'
import Article from '../components/Article.vue'
import NewList from '../components/NewList.vue'
import NewArticle from '../components/NewArticle.vue'

import {NavId} from '../store/store'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/News',
    name: 'News',
    component: NewView,
    redirect: '/NewList',
    children: [
      {
        path: '/NewList',
        name: 'NewList',
        component: NewList
      },
      {
        path: '/NewArticle',
        name: 'NewArticle',
        component: NewArticle
      },
    ]
  },

  {
    path: '/Projucts',
    name: 'Projucts',
    component: ProjuctsView,
    redirect: '/ContentList',
    children: [
      {
        path: '/ContentList',
        name: 'ContentList',
        component: ContentList
      },
      {
        path: '/Detail',
        name: 'Detail',
        component: Detail
      },
    ]
  },
  {
    path: '/Technology',
    name: 'Technology',
    component: TechnologyView
  },
  {
    path: '/Achievements',
    name: 'Achievements',
    component: Achievements,
    beforeEnter: (to, from, next) => {
      const redirectPath = '/' + NavId.Route; // 获取 store 中的路径
      // console.log("路径对比", redirectPath, to.fullPath);

      // 当目标路径是 /Achievements 或 /Achievements/ 时，总是重定向到 NavId.Route
      if (to.path === '/Achievements' || to.path === '/Achievements/') {
        if (redirectPath && redirectPath !== to.fullPath) {
          // console.log("if路径更改", redirectPath, to.fullPath);
          next(redirectPath);
        } else {
          // 如果 redirectPath 不存在或与当前路径相同，默认重定向到子路径
          // console.log("else路径更改", redirectPath, to.fullPath);
          next('/Achievements/CallUs'); // 默认子路径，你可以根据需要调整
        }
      } else {
        // console.log("路径执行到了else", redirectPath, to.fullPath);
        next(); // 继续导航到目标路径
      }
    },
    children: [
      {
        path: '/AchievementList',
        name: 'AchievementList',
        component: AchievementList
      },
      {
        path: '/Article',
        name: 'Article',
        component: Article
      },
    ]
  },
  {
    path: '/About',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    beforeEnter: (to, from, next) => {
      const redirectPath = '/' + NavId.Route; // 获取 store 中的路径
      // console.log("路径对比", redirectPath, to.fullPath);

      // 当目标路径是 /About 或 /About/ 时，总是重定向到 NavId.Route
      if (to.path === '/About' || to.path === '/About/') {
        if (redirectPath && redirectPath !== to.fullPath) {
          // console.log("if路径更改", redirectPath, to.fullPath);
          next(redirectPath);
        } else {
          // 如果 redirectPath 不存在或与当前路径相同，默认重定向到子路径
          // console.log("else路径更改", redirectPath, to.fullPath);
          next('/About/CallUs'); // 默认子路径，你可以根据需要调整
        }
      } else {
        // console.log("路径执行到了else", redirectPath, to.fullPath);
        next(); // 继续导航到目标路径
      }
    },
    children: [
      {
        path: '/CallUs',
        name: 'CallUs',
        component: CallUs
      },
      {
        path: '/AboutImage',
        name: 'AboutImage',
        component: AboutImage
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 添加 beforeEach 路由守卫
router.beforeEach((to, from, next) => {
  // 在页面跳转前，将滚动位置设置为顶部
  window.scrollTo(0, 0);
  next();
});

// 添加一个方法来强制重定向，即使是相同路径的导航
const forceRedirect = (path) => {
  // 使用 setTimeout 确保导航是在当前事件循环之后执行
  setTimeout(() => {
    router.push(path);
  }, 0);
};

// 在 router 实例中监听每次的导航完成事件
router.afterEach((to, from) => {
  // 如果是从 /About 导航到 /About 时，强制重定向到 NavId.Route
  if (to.path === '/About' || to.path === '/About/') {
    const redirectPath = '/' + NavId.Route;
    if (redirectPath && redirectPath !== to.fullPath) {
      // console.log("afterEach强制重定向", redirectPath, to.fullPath);
      forceRedirect(redirectPath);
    } else {
      // 默认重定向到一个子路径
      forceRedirect('/About/CallUs'); // 默认子路径，你可以根据需要调整
    }
  }
    // 如果是从 /Achievements 导航到 /Achievements 时，强制重定向到 NavId.Route
    if (to.path === '/Achievements' || to.path === '/Achievements/') {
      const redirectPath = '/' + NavId.Route;
      if (redirectPath && redirectPath !== to.fullPath) {
        // console.log("afterEach强制重定向", redirectPath, to.fullPath);
        forceRedirect(redirectPath);
      } else {
        // 默认重定向到一个子路径
        forceRedirect('/Achievements/AchievementList'); // 默认子路径，你可以根据需要调整
      }
    }
});
export default router
