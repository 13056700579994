<!-- 无缝轮播图 -->


<template>
    <div class="photo-wall" ref="photoWallRef" @mouseenter="pauseScrolling" @mouseleave="startScrolling">
        <!-- 包含两个图片列表以实现无缝滚动 -->
        <div class="photo-list" ref="photoListRef">
            <div v-for="(image, index) in photoInfo" :key="'image-' + index" class="photo-item">
                <div @click="toNew(image)">
                    <img :src="image.url" :alt="'Photo ' + index" @mousemove="Detail = image.title; Name = image.label"
                        @mouseout="Detail = '做好每一个产品,为更好的明天'; Name = '系列产品'" />
                    <div class="label">
                        {{ image.label }}
                    </div>
                </div>

            </div>
            <div v-for="(image, index) in photoInfo" :key="'image-duplicate-' + index">
                <div>
                    <img :src="image.url" :alt="'Photo ' + index" @mousemove="Detail = image.title; Name = image.label"
                        @mouseout="Detail = '做好每一个产品,为更好的明天'; Name = '系列产品'" />
                    <div class="label">
                        {{ image.label }}
                    </div>
                </div>
            </div>
        </div>


    </div>
    <el-col :span="24" class="detail" style="height: 150px;">
        <el-row justify="center">
            <el-col :span="20">
                <div class="name">
                    {{ Name }}
                </div>
                <div class="describe">
                    {{ Detail }}
                </div>
            </el-col>
        </el-row>

    </el-col>
    <div>
        <button class="buttonToMore" @click="toMore">查看更多</button>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { getAllType } from '../api/apis';
import { useRoute, useRouter } from 'vue-router';
import { NavId } from '../store/store';
const Detail = ref('系列产品');
const Name = ref('做好每一个产品,为更好的明天');
const photoInfo = ref([{ url: "https://cdn.pixabay.com/photo/2012/12/27/19/41/floating-tire-72963_640.jpg", name: "泳圈", text: "这是个泳圈" },
{ url: "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_640.jpg", name: "小鸟", text: "这是个小鸟" },
{ url: "https://cdn.pixabay.com/photo/2024/05/18/08/16/cat-8769861_640.jpg", name: "小猫", text: "这是个小猫" },
{ url: "https://cdn.pixabay.com/photo/2024/05/26/00/40/lizard-8787888_1280.jpg", name: "蜥蜴", text: "这是个蜥蜴" },
{ url: "https://cdn.pixabay.com/photo/2023/08/15/15/48/swan-8192196_640.jpg", name: "鸭子", text: "这是个鸭子" },
{ url: "https://cdn.pixabay.com/photo/2017/03/17/21/32/shell-2152731_1280.jpg", name: "贝壳", text: "这是一些贝壳" },
],);

const getProjucts = async () => {
    try {
        const result = await getAllType();
        photoInfo.value = result.data;
        // console.log(photoInfo.value)
    } catch (error) {
        console.error(error);
    }
}
// 跳转页面
const router = useRouter();
const toNew = (info) => {
    NavId.setRoute(info.routerName)
    NavId.setID(info.id)
    NavId.setName(info.label)
    NavId.setParentID(info.id)
    NavId.setParentName(info.label)
    router.push('/ContentList');
}
const toMore = (info) => {
    // setNavIDName(info)
    // drawer.value = false
    // router.push(info.router);
    // console.log(photoInfo.value[0].id)
    NavId.setRoute(photoInfo.value[0].routerName)
    NavId.setID(photoInfo.value[0].id)
    NavId.setName(photoInfo.value[0].label)
    NavId.setParentID(photoInfo.value[0].belongs)
    NavId.setParentName(photoInfo.value[0].belongsName)
    router.push('/ContentList');
}


// 引用 DOM 元素
const photoWallRef = ref(null);
const photoListRef = ref(null);

// 滚动速度
const scrollSpeed = 1; // 可根据需求调整速度

// 滚动逻辑
let scrollInterval;

const startScrolling = () => {
    // 如果已经存在滚动 interval，先清除它
    if (scrollInterval) {
        clearInterval(scrollInterval);
    }
    scrollInterval = setInterval(() => {
        if (photoWallRef.value && photoListRef.value) {
            photoWallRef.value.scrollLeft += scrollSpeed;
            // 重置滚动位置以实现无缝滚动
            if (photoWallRef.value.scrollLeft >= photoListRef.value.scrollWidth / 2) {
                photoWallRef.value.scrollLeft = 0;
            }
        }
    }, 16); // 大约每秒 60 帧
};

const pauseScrolling = () => {
    // 清除滚动的 interval 以暂停
    clearInterval(scrollInterval);
};

onMounted(() => {
    startScrolling();
    getProjucts()
});

onBeforeUnmount(() => {
    clearInterval(scrollInterval);
});
</script>

<style scoped lang="scss">
.detail {
    margin: 40px 0;
    font-size: 0.8em;

    .name {
        font-size: 0.9em;
        font-weight: 500;
    }

    .describe {
        font-size: 0.7em;
        margin: 20px 0 0 0;
    }
}

.buttonToMore {
    cursor: pointer;
    border-radius: 10px;
    border: none;
    padding: auto;
    width: 300px;
    height: 60px;
    margin: 10px 0;
}

.label {
    height: 20px;
    font-size: small;
    text-align: center;
    background-color: rgba(83, 83, 83, 0.6);
    margin: 0 10px;
    color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-wall {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    /* 提示用户可以交互 */
}

.photo-list {
    display: flex;
}

.photo-list img {
    width: 400px;
    /* 根据图片实际尺寸调整 */
    height: 300px;
    /* 根据图片实际尺寸调整 */
    object-fit: cover;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .name {
        font-size: 0.7em !important;
        font-weight: 500;
    }

    .describe {
        font-size: 0.5em !important;
        margin: 20px 0 0 0;
    }
}
</style>