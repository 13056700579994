<!-- 留言 -->
<template>
    <div class="message">

        <el-row>
            <el-col :span="24">
                <div style="font-size: 1.6em;font-weight: 600;margin-top: 0.6em;">您的意见非常宝贵</div>
            </el-col>
            <el-col :span="24">
                <el-input v-model="info.name" size="large" placeholder="姓名" clearable class="inputs" />
            </el-col>
            <el-col :span="24">
                <el-input v-model="info.tel" type="tel" size="large" placeholder="手机" clearable class="inputs" />
            </el-col>
            <el-col :span="24">
                <el-input v-model="info.email" type="email" size="large" placeholder="邮箱" clearable class="inputs" />
            </el-col>
            <el-col :span="24">
                <el-input v-model="info.content" maxlength="30" :autosize="{ minRows: 3, maxRows: 6 }"
                    placeholder="建议/问题" clearable show-word-limit type="textarea" class="inputs" />
            </el-col>
            <el-col :span="24">
                <el-button type="success" text bg class="inputs" size="large" @click="submit">
                    提交
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { addMessage } from '../api/apis';
const info = ref({
    content: '',
    name: '',
    tel: '',
    email: ''
});
const submit = () => {
    console.log(info.value);
    if (info.value.content=='' || info.value.name=='' || info.value.tel=='' || info.value.email=='') {
        ElMessage.error('请填写完整信息');
    } else {
        addMessage(info.value).then(res => {
            ElMessage.success('提交成功');
            info.value = {
                content: '',
                name: '',
                tel: '',
                email: ''
            };
        });
    }

};
</script>

<style scoped>
.message {
    width: 100%;
    height: 100%;
}

.inputs {
    width: 80%;
    margin: 10px 0;
}
</style>
