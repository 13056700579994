<template>
    <div class="box">
      <transition-group name="fade" tag="div" class="custom-viewer" v-viewer>
        <img v-for="(imageSrc, index) in Images" 
             :key="imageSrc.id || index" 
             :src="imageSrc.url" 
             :alt="imageSrc.instructions"
             :title="imageSrc.instructions" 
             class="gallery-image animate__animated" 
             :class="{ 'animate__flipInY': showImages[index] }" 
             v-show="showImages[index]">
      </transition-group>
    </div>
  </template>
  
  <script setup>
  import { onMounted, ref, onBeforeMount } from 'vue';
  import { getCompanyZi } from '../api/apis';
  
  const Images = ref([]);
  const showImages = ref([]);
  
  const getCompanyZis = async () => {
    try {
      const result = await getCompanyZi();
      Images.value = result.data;
      showImages.value = Array(result.data.length).fill(false);
      showImagesOneByOne();
    } catch (error) {
      console.error(error);
    }
  };
  
  const showImagesOneByOne = () => {
    Images.value.forEach((_, index) => {
      setTimeout(() => {
        showImages.value[index] = true;
      }, index * 500); // Adjust the delay as needed
    });
  };
  
  onBeforeMount(() => {
    getCompanyZis();
  });
  </script>
  
  <style scoped>
  .box {
    padding: 10px 60px;
  }
  
  .custom-viewer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */

    gap: 40px;
  }
  
  .gallery-image {
    cursor: pointer;
    flex: 1 1 calc(20% - 10px);
    max-width: calc(20% - 10px);
    box-sizing: border-box;
    object-fit: cover;
    padding: 20px;
    box-shadow: 
      inset #059c8e 0 0 0 1px,
      inset #0cab9c 0 0 0 2px,
      inset #1fbdae 0 0 0 8px,
      inset #8ce9ff 0 0 0 10px,
      inset #48e4d6 0 0 0 12px,
      inset #e5f9f7 0 0 0 16px,
      inset #bfecf7 0 0 0 17px;
  }
  
  /* Media queries */
  @media (max-width: 1200px) {
    .gallery-image {
      flex: 1 1 calc(25% - 10px);
      max-width: calc(25% - 10px);
    }
  }
  
  @media (max-width: 992px) {
    .gallery-image {
      flex: 1 1 calc(33.33% - 10px);
      max-width: calc(33.33% - 10px);
    }
  }
  
  @media (max-width: 768px) {
    .gallery-image {
      flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 576px) {
    .gallery-image {
      flex: 1 1 calc(100% - 10px);
      max-width: calc(100% - 10px);
    }
  }
  </style>
  