<!--  -->
<template>
  <header-image></header-image>
  <div class="list" v-if="!isMobile">

    <div v-for="projuct in ProjuctsType" :key="projuct.id" @click="checkType(projuct)" class="projuctList"
      :class="{ 'active': projuct.id === NavId.ID }">
      {{ projuct.label }}
    </div>

    <!--   -->
  </div>
  <el-affix :offset="64" style="margin-top: 0.6em;" z-index=15>
    <el-button type="primary" @click="drawer = true" v-if="isMobile" style="width: 100%;">查看全部</el-button>
  </el-affix>
  <el-drawer v-model="drawer" title="全部系列产品" size="80%" :z-index="70" >
    <el-menu :default-active="NavId.ID.toString()" class="el-menu-vertical-demo">
      <template v-for="projuct in ProjuctsType" :key="projuct.id" style="border:none">
        <!-- 渲染没有子菜单的菜单项 -->
        <el-menu-item :index="projuct.id.toString()">
          <span @click="checkType(projuct)">{{ projuct.label }}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </el-drawer>
  <div>
    <!-- <ContentList @click="go"></ContentList> -->
    <router-view></router-view>


  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch } from 'vue';
import { getAllType } from '../api/apis'
import { NavId, Projucts } from '../store/store'
import HeaderImage from '../components/HeaderImage.vue';

// import router from '@/router';
import { useRouter } from 'vue-router';

// 弹出层开关
const drawer = ref(false)
// 手机端开关
const isMobile = ref(window.innerWidth <= 768);
// 产品类别  ---获取所有类型
const ProjuctsType = ref([]);
const getAllTypes = async () => {
  try {
    const result = await getAllType();
    ProjuctsType.value = result.data;
    // NavId.setID(ProjuctsType.value[0].id)
    // console.log("打印产品类别", ProjuctsType.value)
  } catch (error) {
    console.error(error);
  }
};
// 切换开关
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  if (isMobile.value) {
    drawer.value = false;
  }
};
// 跳转路由
const router = useRouter();
const checkType = (info) => {
  // console.log(info);
  NavId.setID(info.id)
  NavId.setName(info.label)
  drawer.value = false
  router.push({ name: 'ContentList', query: { name: info.label } });
};
const IDD = ref(NavId.ID)
watch(() => NavId.ID,
  (newId) => {
    IDD.value = newId ? newId : 1000
  },
  { immediate: true }
)
onMounted(() => {

  getAllTypes()
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  // console.log("注销")
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 62, 80);
}

.projuctList {
  min-width: 120px;
  height: 40px;
  // border-radius: 5px;
  background-color: rgba(44, 62, 80, 0.8);
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  padding: 0 1.2em;
  cursor: pointer;
}

.active {
  // background-color: rgba(157, 178, 223, 0.8);
  color: rgba(52, 152, 219);
  background-color: rgb(255, 255, 255);
}
</style>
