

<!-- 产品详情 -->


<template>
  <div>
    <el-row justify="center">
      <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20" class="box">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/Projucts' }">产品</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/Projucts' }">{{ NavId.Name }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="oneProjuct">{{ oneProjuct.name }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="oneProjuct">{{ oneProjuct.model }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col :xs="20" :sm="20" :md="18" :lg="18" :xl="18">
        <el-row justify="space-between">
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="left">
            <BlowUpIMG v-if="oneProjuct" :images="JSON.parse(oneProjuct.urls).images"></BlowUpIMG>
          </el-col>
          <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14" class="right">
            <el-row justify="center">
              <el-col :span="18" v-if="oneProjuct" style="font-size: 1.6em;font-weight:700;color: red;margin: 2px 0 8px 0;">{{ oneProjuct.model }}{{oneProjuct.name }}</el-col>
              <el-col :span="18" v-if="oneProjuct" style="margin: 2px 0 30px 0; text-indent: 2em;">{{ oneProjuct.describe }}</el-col>
              <el-col :span="12" v-if="oneProjuct" style="margin: 2px 0 20px 0;font-size: 1.2em;font-weight:600">产品型号</el-col>
              <el-col :span="12" v-if="oneProjuct" style="margin: 2px 0 20px 0;font-size: 1.2em;font-weight:600">产品尺寸</el-col>
              <el-col :span="12" v-if="oneProjuct" style="margin: 2px 0 20px 0;">{{ oneProjuct.model }}</el-col>
              <el-col :span="12" v-if="oneProjuct">{{ oneProjuct.size }}</el-col>
              <el-col :span="12"><a href="#info"><el-button type="warning" size="large" style="width: 50%;" >产品规格</el-button></a></el-col>
              <el-col :span="12"><el-button type="danger" size="large"  style="width: 50%;">对接微信小程序</el-button></el-col>
            </el-row>
          </el-col>
        </el-row>

      </el-col>

    </el-row>
    <el-row justify="center" style="margin-top: 60px;" id="info" v-if="Instructions!=''">
      <el-col :span="18">
        <el-tabs type="border-card">
          <el-tab-pane v-for="item in Instructions" :label="item.title">
            <img :src="item.url" alt="" style="width: 100%;height: 600px;cursor: pointer;"   v-viewer>
          </el-tab-pane>
        </el-tabs></el-col>
    </el-row>

  </div>
</template>

<script setup>
import { ArrowRight } from '@element-plus/icons-vue';
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getOne,getInstructions } from '../api/apis';
import { NavId } from '../store/store';
import BlowUpIMG from '../components/BlowUpIMG.vue'



const route = useRoute();
const router = useRouter();

const ProjuctID = ref(route.query.ID || 0)

watch(() => route.query.ID, (newValue) => {
  ProjuctID.value = newValue
});
const oneProjuct = ref()
const getOneProjuct = async (id) => {
  try {
    const result = await getOne(id);
    oneProjuct.value = result.data[0];
    // console.log(oneProjuct.value.name)
  } catch (error) {
    console.error(error);
  }
}
const Instructions = ref()
const getInstruction = async (id) => {
  try {
    const result = await getInstructions(id);
    Instructions.value = result.data;
    // console.log(Instructions.value)
  } catch (error) {
    console.error(error);
  }
}
onBeforeMount(() => {
  
  getOneProjuct(ProjuctID.value)
  getInstruction(ProjuctID.value)
  // console.log(ProjuctID.value)
})
</script>

<style scoped>
a{
  text-decoration: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  padding: 1.2em 2.2em;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
