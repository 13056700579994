<template>
    <el-row justify="center">
        <el-col :span="22">
            <el-row justify="start">
                <el-col :xs="6" :sm="6" :lg="2" :xl="2">
                    <div style="display: flex;justify-content: center;align-items: center;margin: 10px;cursor:pointer;" @click="goBack">
                        <el-icon :size="20">
                            <Back />
                        </el-icon>返回
                    </div>
                </el-col>
            </el-row>


        </el-col>

    </el-row>
    <el-row justify="center">
        <el-col :span="22" v-if="allData">
            <div class="ql-container ql-snow ql-box">
                <div class="box">
                    <div class="title">{{ allData.title }}</div>
                    <div class="time">发布时间：{{ allData.time }}</div>
                    <div class="view">浏览量 ： {{ allData.pageview }}</div>
                </div>
                <div class="ql-editor">
                    <div v-html="allData.content"></div>
                </div>
            </div>
        </el-col>
    </el-row>
</template>
<script setup>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { getOneArticle } from '../api/apis'
import { onMounted, ref, onBeforeUnmount } from 'vue'
import { Back } from '@element-plus/icons-vue'
const id = ref(JSON.parse(sessionStorage.getItem('ArticleId') || null))
const allData = ref()
const content = ref('')
onMounted(async () => {
    try {
        // console.log("打印id", id.value)
        const result = await getOneArticle(id.value);

        allData.value = result.data;
        // console.log("打印", allData.value)
    }
    catch (error) {
        console.error(error);
    }
})


import { useRouter } from 'vue-router';

const router = useRouter();

function goBack() {
  router.go(-1);
}

onBeforeUnmount(() => {
    sessionStorage.removeItem('ArticleId')
})
</script>
<style lang="scss" scoped>
.ql-box {
    padding: 10px 0px;
}

.box {


    .title {
        font-size: 2em;
        font-weight: 600;
    }

    .time {
        display: flex;
        justify-content: end;
    }

    .view {
        display: flex;
        justify-content: end;
        width: 100%;
        border-bottom: 1px solid rgb(163, 163, 163);
        padding: 5px 0;
    }
}

.ql-editor {
    height: auto;
}
</style>