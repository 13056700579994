<template>
  <div class="website">
    <NavBar></NavBar>
    <div class="content">
      <Customer></Customer>
      <el-backtop :right="30" :bottom="100" />
      <router-view></router-view>
    </div>
    <section class="footer">
      <Fotter></Fotter>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import NavBar from './components/NavBar.vue';
import Customer from './components/Customer.vue';
import Fotter from './components/Fotter.vue';

const buttons = [
  { id: 'panel1', label: '首页', router: "/", subItems: [] },
  { id: 'panel2', label: '产品中心', router: "/Projucts", subItems: [{ id: 'sub2-1', label: '子按钮2-1' }, { id: 'sub2-2', label: '子按钮2-2' }] },
  { id: 'panel3', label: '技术中心', router: "/Technology", subItems: [{ id: 'sub3-1', label: '子按钮3-1' }, { id: 'sub3-2', label: '子按钮3-2' }] },
  { id: 'panel4', label: '关于我们', router: "/about", subItems: [{ id: 'sub4-1', label: '子按钮4-1' }, { id: 'sub4-2', label: '子按钮4-2' }] },
  { id: 'panel5', label: '新闻中心', router: "/new", subItems: [{ id: 'sub3-1', label: '子按钮3-1' }, { id: 'sub3-2', label: '子按钮3-2' }] },
];

const activePanel = ref(null);
const isMobile = ref(window.innerWidth <= 768);


const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  if (isMobile.value) {
    activePanel.value = null;
    // panelVisible.value = false;
  }
};

// 向下色深改变
const isTransparent = ref(false);



function ScrollUpDown() {
  isTransparent.value = window.pageYOffset > 0;
}
onMounted(() => {
  window.addEventListener('resize', handleResize);
  window.addEventListener('scroll', ScrollUpDown);
});

onUnmounted(() => {
  // console.log("注销")
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('scroll', ScrollUpDown);
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8f9fa; /* 柔和的浅灰色背景 */
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
}

.website {
  width: 100vw;
  ;
  height: 100%;
  /* position: absolute; */

}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #33333377;
  color: white;
  padding: 16px 20px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 50;
  /* 确保导航栏在顶层 */
}

.navbar:hover {
  background-color: #333333;
}

.transparent {
  background-color: rgba(0, 0, 0, 1);
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.nav-buttons {
  display: flex;
}

.nav-button {
  margin-left: 20px;
  padding: 8px 15px;
  cursor: pointer;
  background-color: #444;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btns {
  width: 100%;
  height: 100%;
}

.nav-button {
  /* 基础样式，可以根据需要调整 */
  cursor: pointer;
  box-shadow: 0 0 5px rgba(167, 167, 167, 0.5);
  /* 初始阴影效果 */
  transition: box-shadow 0.9s ease-in-out;
  /* 平滑过渡效果 */
}

/* 定义呼吸灯动画 */
@keyframes breathingEffect {

  0%,
  100% {
    box-shadow: 0 0 10px rgba(160, 160, 160, 0.5);
    /* 阴影最小，蓝色 */
  }

  50% {
    box-shadow: 0px 0px 20px 8px rgba(255, 255, 255, 0.8);
    /* 阴影最大，红色 */
  }
}

/* 悬浮时触发动画 */
.nav-button:hover {
  animation: breathingEffect 3s infinite;
  /* 动画持续时间2秒，无限循环 */
  animation-direction: alternate;
  /* 动画方向交替，实现由小到大再到小的效果 */
}

.nav-button:hover {
  background-color: #555;
}

.menu-button {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}

.panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  background-color: #222;
  color: white;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: opacity 0.3s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.mobile-sidebar {
  position: fixed;
  top: 2.6rem;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #222;
  color: white;
  z-index: 30;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(0);
  transition: transform 0.3s ease;
  overflow-y: auto;
}

.mobile-nav-item {
  margin-bottom: 20px;
}

.mobile-nav-button {
  padding: 10px;
  background-color: #444;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav-button:hover {
  background-color: #555;
}

.arrow {
  margin-left: 10px;
}

.submenu {
  padding-left: 20px;
  margin-top: 10px;
}

.mobile-sub-nav-item {
  padding: 10px;
  background-color: #555;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.mobile-sub-nav-item:hover {
  background-color: #666;
}

@media (max-width: 768px) {
  .navbar {
    background-color: rgb(0, 0, 0);
  }

  .nav-buttons {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .panel {
    display: none;
  }
}
</style>
