<template>
    <el-row justify="center" class="bg">
        <el-col :span="14">
            <el-row>
                <el-col v-for="item in Articles" :key="item.id" :span="24" class="card  animate__animated animate__fadeInRight">
                    <el-row :gutter="40" class="custom-container">
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="responsive-image">
                            <img :src="JSON.parse(item.cover)" />
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" class="content-container">
                            <div class="header">
                                <div class="title">{{ item.title }}</div>
                                <div class="time">
                                    <el-icon class="icon">
                                        <Clock />
                                    </el-icon>
                                    {{ item.time }}
                                </div>
                            </div>
                            <div class="description">
                                <p>{{ item.intro }}</p>
                            </div>
                            <div class="button-container">
                                <el-button type="primary" text @click="toLookArticle(item.id)">
                                    查看详情&nbsp
                                    <el-icon>
                                        <DArrowRight />
                                    </el-icon>
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getOneTypeArticle } from '../api/apis'
import { Clock, DArrowRight } from '@element-plus/icons-vue'
import { NavId } from '../store/store'

const Articles = ref([])
const getArticle = async (id) => {
    try {
        const result = await getOneTypeArticle(id)
        Articles.value = result.data
    } catch (error) {
        console.error(error)
    }
}
const route = useRouter()
const toLookArticle = (id) => {
    sessionStorage.setItem('ArticleId', id)
    route.push('/NewArticle')
}

watch(() => NavId.ID, () => {
    getArticle(NavId.ID)
})
getArticle(NavId.ID)
</script>

<style lang="scss" scoped>
.bg{
    min-height: 600px;
}
.card {
    margin: 1.2em 0;
    // background-color: rgba(44, 62, 80, 0.9);
    // backdrop-filter: blur(5px);
    border-radius: 10px;
    padding: 1em;
    color: #ecf0f1;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

    transition: background-color 0.3s, box-shadow 0.3s;
    &:hover {
        // background-color: #34495e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }
}

.custom-container {
    display: flex !important;
    flex-wrap: wrap !important;
}

.responsive-image {
    height: 200px;
    overflow: hidden;
    border-radius: 8px;


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }
}

.content-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 200px; // Ensure the same height as the image for proper alignment
    padding: 10px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 24px;
        color: #555;

    }

    .time {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        color: #bdc3c7;

        .icon {
            margin-right: 0.5em;
        }
    }
}

.description {
    flex: 1 !important;
    color: #555;
    padding: 5px;

    p {
        margin: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}

.button-container {
    display: flex !important;
    justify-content: flex-end !important;
}

@media (max-width: 768px) {
    .bg{
        min-height: auto;
    }
    .custom-container {
        flex-direction: column;
    }

    .responsive-image {
        margin-bottom: 1em;

        img {
            height: auto;
        }
    }

    .content-container {
        height: auto;
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.5em;

        .title {
            margin-bottom: 0.5em;
        }

        .time {
            font-size: 0.8em;
        }
    }

    .description {
        margin-bottom: 1em;
        font-size: 1em;
    }

    .button-container {
        justify-content: center;
    }
}
</style>
