<template>
    <div class="index">
        <section id="section1">
            <div class="carousel">
                <Carousel :images="carouselImages"></Carousel>
            </div>
        </section>
        <section id="section2">
            <el-row style="width: 100%;">
                <el-col :span="24" class="top">
                    <el-row>
                        <el-col :span="24" class="title">系列产品</el-col>
                        <!-- <br /> -->
                        <el-col :span="24"
                            class="describe">把技术变成产品是知识经济时代的明显特征，也是工程师的天职。</el-col>
                    </el-row>
                </el-col>
                <el-col :span="24" class="bottom">
                    <!-- <div>循环产品</div> -->
                    <el-row>
                        <el-col :span="24">
                            <CarouselPlay></CarouselPlay>
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>
        </section>
        <section id="section3">
            <el-row style="min-height: 100vh;padding: 60px 0 0 0;" justify="center">
                <el-col :span="20" style="min-height: 100%;padding:  0 10px;">
                    <div style="margin-bottom: 20px;">
                        <div style="font-size: 2.5rem;font-weight: 600;margin-bottom: 4px;">新闻中心</div>
                        <div style="font-size: 0.4em;margin-bottom: 5px;">走在消息的最前线，决策快人一步</div>
                    </div>
                    <el-row :gutter="40">
                        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                            <el-row>
                                <el-col :span="24" style="font-size: 0.5em;margin-bottom: 10px;">
                                    行业新闻
                                    <el-button type="primary" link style="float: right;" @click="toNews()">更多</el-button>
                                </el-col>
                                <el-col :span="24">
                                    <el-row justify="space-between">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11" v-for="item in News"
                                            :key="item.id" class="articleCard" @click="toNewsArticle(item)">
                                            <img :src="JSON.parse(item.cover)" alt=""
                                                style="width: 100%;height: 200px;object-fit: cover;">
                                            <el-col :span="24" class="content-container">
                                                <div class="header">
                                                    <div class="title">{{ item.title }}</div>
                                                    <div class="time">
                                                        <el-icon class="icon">
                                                            <Clock />
                                                        </el-icon>
                                                        {{ item.time }}
                                                    </div>
                                                </div>
                                                <div class="description">
                                                    <p>{{ item.intro }}</p>
                                                </div>
                                            </el-col>

                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                            <el-row style="margin-top: 20px;">
                                <el-col :span="24"
                                    style="font-size: 0.5em;margin-top: 10px;margin-bottom: 20px;">业绩新闻
                                    <el-button type="primary" link style="float: right;" @click="toAchievements()">更多</el-button></el-col>
                                <el-col :span="24">
                                    <el-row justify="space-between">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="11" :xl="11"
                                            v-for="item in AchievementsArticle" :key="item.id" class="articleCard" @click="toNewsArticle(item)">
                                            <img :src="JSON.parse(item.cover)" alt=""
                                                style="width: 100%;height: 200px;object-fit: cover;">
                                            <el-col :span="24" class="content-container">
                                                <div class="header">
                                                    <div class="title">{{ item.title }}</div>
                                                    <div class="time">
                                                        <el-icon class="icon">
                                                            <Clock />
                                                        </el-icon>
                                                        {{ item.time }}
                                                    </div>
                                                </div>
                                                <div class="description">
                                                    <p>{{ item.intro }}</p>
                                                </div>
                                            </el-col>

                                        </el-col>
                                    </el-row>
                                </el-col>

                            </el-row>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="AutoScrollList">
                            <AutoScrollList style="height: 72vh;border:ridge 10px rgb(255, 255, 255);" />
                        </el-col>
                    </el-row>
                </el-col>

            </el-row>
        </section>
        <section id="section4">

            <el-row>
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" style="color: azure;" class="spirit">

                    <el-row class="row-bg" justify="space-between">
                        <el-col :span="24" class="spirit">
                            <el-row class="row-bg" justify="center">
                                <el-col :span="4">
                                    -
                                </el-col>
                                <el-col :span="16">
                                    <p>公司介绍</p>
                                </el-col>
                                <el-col :span="4" font-size="2px">
                                    -
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="12" class="spirit">
                            <h5>专业</h5>
                        </el-col>
                        <el-col :span="12" class="spirit">
                            <h5>高效</h5>
                        </el-col>
                        <el-col :span="12" class="spirit">
                            <h5>服务</h5>
                        </el-col>
                        <el-col :span="12" class="spirit">
                            <h5>合作</h5>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
                    <div
                        style="background-color: rgba(255, 255, 255, 0.5);width: 100%;height: 100%;text-align: center;padding: 20px 10px;">


                        <text class="time">SINCE 2015</text><br>

                        <text class="name">山西卓立佰电气有限公司</text><br>

                        <text class="title" style="font-size: 0.5em;">SHANXI CHOLIPAk ELECTRIC CO.,LTD</text><br>

                        <p class="info" style="font-size: large;text-indent: 2em;text-align: left;">
                            山西卓立佰电气有限公司位于山西转型综合改革示范区唐槐产业园区电子街9号，注册资本3000万元。
                            公司为国家高新技术企业、山西省专精特新企业、山西省智能制造示范试点企业。山西省机械工程学会副理事长单位。
                            是太原通泽重工控股的专业从事工业电气自动化控制的科技型公司。公司主营：高低压输配电成套设备、
                            交直流电力拖动成套设备、工业自动化控制设备、新能源供电成套设备、水利水电及自动化工程等研发设计、制造、销售、技术服务、
                            进出口贸易等。
                        </p>
                        <p class="info" style="font-size: large;text-indent: 2em;text-align: left;">
                            公司产品已成功应用于冶金、机械、物流、化工、环保、水利等行业。典型应用热轧无缝钢管生产线电气与自动化、中部
                            引黄供配电控制工程、汾河二库提水自动化工程、禹门口提水与节水灌溉电气控制工程项目。公司产品已出口到印度、伊朗等国家。
                            公司研制的基于工业互联网技术的重型装备远程监控系统、工业基础自动化与过程控制系统、交直流电机全数字电力拖动系统、电动
                            汽车交直流充电机、明渠灌溉闸门控制装置、中小型水利水电计算机监控系统、DCS垃圾焚烧发电系统已得到广泛应用。
                        </p>
                    </div>

                </el-col>
            </el-row>
        </section>
    </div>
</template>
<script setup>
import { onMounted, ref, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router'
import Carousel from '../components/Carousel.vue';
import CarouselPlay from '../components/carouselPlay.vue';
import { getCarousel, getIndustryNews, getAllAchievementArcitle, getAllTrueArcitle } from '../api/apis';
import AutoScrollList from '../components/AutoScrollList.vue';
import { NavId } from '../store/store'
import { Clock, DArrowRight } from '@element-plus/icons-vue'

const router = useRouter();
const toNews = () =>{
    NavId.setID('20241004')
    NavId.setName('新闻中心')
    NavId.setRoute('/NewList')
    NavId.setParentID('20241004')
    NavId.setParentName('新闻中心')
    router.push({ path: '/NewList' })
}
const toAchievements = () =>{
    // NavId.setNavIDName('Achievements')
    NavId.setID(20241018)
    NavId.setName('焦煤行业')
    NavId.setRoute('/AchievementList')
    NavId.setParentID('20241005')
    NavId.setParentName('公司业绩')
    router.push({ path: '/AchievementList' })
}

const News = ref()
// 获取行业新闻
const getNews = async () => {
    try {
        const result = await getIndustryNews();
        // console.log(result.data);
        News.value = result.data;
    } catch (error) {
        console.error(error);
    }
}
const AchievementsArticle = ref()
// 获取业绩新闻
const getAchievements = async () => {
    try {
        const result = await getAllAchievementArcitle();
        AchievementsArticle.value = result.data;
    } catch (error) {
        console.error(error);
    }
}
// 跳转详情
const toNewsArticle = (info) => {
    // console.log(info)
    if(info.belongs=="20241004"){
        NavId.setID('20241004')
    NavId.setName('新闻中心')
    NavId.setRoute('/NewList')
    NavId.setParentID('20241004')
    NavId.setParentName('新闻中心')
    }else{
    //     NavId.setID(20241018)
    // NavId.setName('焦煤行业')
    NavId.setRoute('/AchievementList')
    NavId.setParentID('20241005')
    NavId.setParentName('公司业绩')
    }
    sessionStorage.setItem('ArticleId', info.id)
    router.push({ path: '/Article' })
}

const Details = ref('暂无详情')
const carouselImages = ref([])
const getCarousels = async () => {
    try {
        const result = await getCarousel();
        carouselImages.value = result.data;
    } catch (error) {
        console.error(error);
    }
}



let idNodes = ref(0)
const currentIndex = ref(0)
const scrollToNextId = (direction) => {
    currentIndex.value += direction;
    // 确保索引在有效范围内
    currentIndex.value = Math.min(Math.max(currentIndex.value, 0), idNodes.value.length - 1);

    // 滚动到目标ID节点
    const targetNode = idNodes.value[currentIndex.value];
    window.scrollTo({
        top: targetNode.offsetTop,
        behavior: 'smooth'
    });
}

// 防抖函数
const debounce = (func, wait) => {
    let timeout = 500;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}
// 使用debounce包装的滚动处理函数
const debouncedScrollHandler = debounce((e) => {
    const direction = e.deltaY > 0 ? 1 : -1;
    scrollToNextId(direction);
}, 100);


onBeforeMount(() => {
    getCarousels();
    getNews();
    getAchievements();
});

onMounted(() => {
    // 获取所有ID节点并排序
    idNodes.value = Array.from(document.querySelectorAll('section')).sort((a, b) => a.offsetTop - b.offsetTop);
    // console.log(idNodes.value)
    // 当前可视区域中的ID节点索引

    // 监听滚轮事件
    window.addEventListener('wheel', debouncedScrollHandler); // 设定防抖时间为200毫秒
    const container = document.querySelector('.carousel');
    // container.addEventListener('mouseenter', handleMouseEnter);
})
onBeforeUnmount(() => {
    // 组件销毁前注销滚轮事件监听器
    window.removeEventListener('wheel', debouncedScrollHandler);
});
</script>
<style lang="scss" scoped>
body,
html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* 防止出现滚动条 */
}

.index {
    width: 100%;
}

.carousel {
    width: 100%;
    height: 100vh;
    /* 默认设置为全屏高度 */
}



/* 处理 Swiper 样式以适应不同的高度 */
.mySwiper {
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet {
    background-color: #fff;
    /* 定义分页点的颜色 */
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff;
    /* 定义导航按钮的颜色 */
}

section {

    /* 每个section占满视口高度 */
    width: 100%;

    font-size: 3rem;
}





#section2 {
    // background-color: #0f0;
    width: 100vw;
    min-height: 100vh;
    padding: 0 0.2em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .top {
        width: 100%;
        min-height: 25vh;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: #fccfcf;
        padding-top: 60px;
        box-sizing: border-box;

        .title {
            height: 40px;
            font-size: 2.5rem;
            font-weight: 900;
            margin-bottom: 10px;
        }

        .describe {
            font-size: 0.9rem;
        }
    }

    .bottom {
        width: 100%;
        height: 75vh;
        padding: 20px 10px;
        box-sizing: border-box;
        // background: #ddf59a;
    }
}

#section3 {
    // min-height: 100vh;
    // background-color: cadetblue;
    padding-top: 10px;


    .content-container {
        height: auto;
    }

    .header {
        // flex-direction: column;
        align-items: flex-start;

        .title {
            margin-bottom: 0.2em;
            font-size: 0.4em;
        }

        .time {
            font-size: 0.3em;
        }
    }

    .description {
        font-size: 0.3em;
    }

    .button-container {
        justify-content: center;
    }


}

.articleCard {
    background-color: azure;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    margin: 10px 0;
    transition: background-color 0.3s, box-shadow 0.3s;
    
    &:hover {
        // background-color: #34495e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }
}

#section4 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* 加载背景图 */
    background-image: url(../assets/980.jpg);
    background-color: rgba(0, 0, 0, 0.3);
    /* 半透明的白色背景颜色 */
    background-blend-mode: multiply;
    /* 使用multiply混合模式 */
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    /* 设置背景颜色，背景图加载过程中会显示背景色 */
    min-height: 100vh;
    padding-top: 60px;
    font-size: 2em;
}

.spirit {
    margin: 40px 0;
}

.AutoScrollList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
}

@media (max-width: 768px) {
    #section1 {
        // border: red 2px solid;
        margin-top: 18px !important;
    }
    .carousel {
        height: 30vh;
        /* 移动端设置为 30vh */
        margin-top: 68px !important;
    }

    .spirit {
        margin: 0 0;
    }

    .AutoScrollList {
        display: none !important;
    }
}
</style>