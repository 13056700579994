import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://192.168.1.135:8097',
  timeout: 5000,
});

// 添加请求拦截器
// instance.interceptors.request.use(
//   (config) => {
//     // 在发送请求之前做些什么，例如添加 token
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     // 对请求错误做些什么
//     return Promise.reject(error);
//   }
// );

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    // 对响应错误做点什么，例如处理 401 错误
    if (error.response.status === 401) {
      // 清除 token 和其他登录信息
      localStorage.removeItem('token');
      // 假设你已经有了一个router对象
      // router.push('/login');
    }
    return Promise.reject(error);
  }
);

// 封装常用HTTP方法
const request = {
  get: (url, params={}) => instance.get(url, { params }),
  post: (url, data) => instance.post(url, data),
  put: (url, data) => instance.put(url, data),
  delete: (url) => instance.delete(url),
  patch: (url, data) => instance.patch(url, data)
};

export default request;