<template>
  <HeaderImage></HeaderImage>
  <div class="list" v-if="!isMobile">
    <div v-for="Achievement in AllAchievement" :key="Achievement.id" @click="checkType(Achievement)" class="projuctList"
      :class="{ 'active': Achievement.id === NavId.ID }">
      {{ Achievement.label }}
    </div>
  </div>


  <el-affix :offset="64" style="margin-top: 0.6em;" z-index=15>
    <el-button type="primary" @click="drawer = true" v-if="isMobile" style="width: 100%;">查看全部</el-button>
  </el-affix>

  <el-drawer v-model="drawer" title="全部新闻" size="50%" :z-index="40">
    <el-menu :default-active="NavId.ID.toString()" class="el-menu-vertical-demo">
          <template v-for="Achievement in AllAchievement" :key="Achievement.id" style="border:none">
            <!-- 渲染没有子菜单的菜单项 -->
            <el-menu-item :index="Achievement.id.toString()">
              <span  @click="checkType(Achievement)">{{ Achievement.label }}</span>
            </el-menu-item>
          </template>
        </el-menu>
  </el-drawer>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import HeaderImage from '../components/HeaderImage.vue';
import { NavId } from '../store/store'
import { getAllAchievements } from '../api/apis'


// 手机端开关
const isMobile = ref(window.innerWidth <= 768);
// 手机侧边栏
const drawer = ref(false)
// 数据
const AllAchievement =ref()
const getAllAchievement =async (id)=>{
  // console.log(id)
  try {
    const result = await getAllAchievements(id);
    AllAchievement.value = result.data;
    // console.log("打印类别", AllAchievement.value)
  } catch (error) {
    console.error(error);
  }
}
// 切换开关
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  if (isMobile.value) {
    drawer.value = false;
  }
};
// 跳转路由
const router = useRouter();
const checkType = (info) => {
  // console.log(info);
  NavId.setID(info.id)
  NavId.setName(info.label)
  drawer.value=false
  router.push({ name: info.routerName, query: { name: info.label } });
};
onMounted(() => {
  getAllAchievement(NavId.ParentID)
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  // console.log("注销")
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(44, 62, 80);
}

.projuctList {
  min-width: 120px;
  height: 40px;
  // border-radius: 5px;
  background-color: rgba(44, 62, 80, 0.8);
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: aliceblue;
  padding: 0 1.2em;
  cursor: pointer;
}

.active {
  // background-color: rgba(157, 178, 223, 0.8);
  color: rgba(52, 152, 219);
  background-color: rgb(255, 255, 255);
}
</style>