<template>
  <div class="carousel-container">
    <button class="carousel-button left-button" @click="scrollLeft">←</button>
    <div class="carousel-viewport">
      <div class="carousel-track" :style="{ transform: `translateX(-${currentIndex * 100}px)` }">
        <img v-for="(image, index) in images" :key="index" :src="image" alt="Carousel Image">
      </div>
    </div>
    <button class="carousel-button right-button" @click="scrollRight">→</button>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// 定义图片列表
const images = ref([
  'https://yanxuan-item.nosdn.127.net/a6939f41c48fa9e9c8f7a7ed855351f1.jpg',
          'https://yanxuan-item.nosdn.127.net/0cdfd546f8675669b87716114ad5900a.jpg',
          'https://yanxuan-item.nosdn.127.net/240983ccc935146a4795e3990d30468d.jpg',
          'https://yanxuan-item.nosdn.127.net/d46e005025a5d3b73c4781d31b327558.jpg',
          'https://yanxuan-item.nosdn.127.net/330913911087b44b0d817dd78233165f.png',
]);

const currentIndex = ref(0);

// 计算总图片数量和每次显示的图片数量
const totalImages = images.value.length;
const visibleImages = 3;

const scrollLeft = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
};

const scrollRight = () => {
  if (currentIndex.value < totalImages - visibleImages) {
    currentIndex.value++;
  }
};
</script>

<style scoped>
.carousel-container {
  display: flex;
  align-items: center;
  width: 400px;
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.carousel-viewport {
  overflow: hidden;
  width: 300px; /* 3 images * 100px width */
  height: 100px;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-track img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.carousel-button {
  width: 50px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}
</style>
