import { reactive } from "vue"

export const Projucts = reactive({
    TypeID : JSON.parse(sessionStorage.getItem('TypeID')) || null,
    setTypeID (num){
        this.TypeID=num
        sessionStorage.setItem('TypeID', JSON.stringify(this.TypeID));
    }
})
export const NavId = reactive({
    ID : JSON.parse(sessionStorage.getItem('ID')) || null,
    Name : JSON.parse(sessionStorage.getItem('Name')) || null,
    Route : JSON.parse(sessionStorage.getItem('Route')) || null,
    ParentID : JSON.parse(sessionStorage.getItem('ParentID')) || null,
    ParentName : JSON.parse(sessionStorage.getItem('ParentName')) || null,
    setID (num){
        
        this.ID=num
        // console.log("状态管理的id",this.ID)
        sessionStorage.setItem('ID', JSON.stringify(this.ID));
    },
    setName (name){
        
        this.Name=name
        // console.log("状态管理拿到name",this.Name)
        sessionStorage.setItem('Name', JSON.stringify(this.Name));
    },
    setRoute (route){
        
        this.Route=route
        // console.log("状态管理拿到route",this.Route)
        sessionStorage.setItem('Route', JSON.stringify(this.Route));
    },
    setParentID (ParentID){
        
        this.ParentID=ParentID
        // console.log("状态管理拿到ParentID",this.ParentID)
        sessionStorage.setItem('ParentID', JSON.stringify(this.ParentID));
    },
    setParentName (ParentName){
        
        this.ParentName=ParentName
        // console.log("状态管理拿到ParentName",ParentName)
        sessionStorage.setItem('ParentName', JSON.stringify(this.ParentName));
    }
})