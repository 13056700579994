

<!-- 页脚 -->


<template>
    <!-- <el-row> -->
    <el-col :span="24" class="box">
        <el-row justify="center">
            <el-col :span="20" class="content">
                <el-row  justify="center">
                    <el-col :xs="18" :sm="6" :md="6" :lg="6" :xl="6" class="left">
                        <div style="width: 100%;">
                         <img src="../assets/logo.jpg" style="width: 100%;" alt=""><br/><img src="../assets/FootLogo.png" style="width: 100%;" alt="">
                        </div>
                        
                        
                    </el-col>
                    <el-col :span="18" class="right" v-if="!isMobile">
                        <div v-for="nav in Navs" class="onetype">
                            <div class="label">
                                <p v-show="nav.label !== '网站首页'">{{ nav.label }}</p>
                            </div>
                            <div v-for="item in nav.children" class="item" @click="to(item)">{{ item.label }}</div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <div class="footer-content">
            <p>Copyright © 2024 卓立佰电气</p>
            山西卓立佰电气有限公司版权所有  晋ICP备15004085号-1 网站建设：山西卓立佰电气有限公司
        </div>
    </el-col>
    <!-- </el-row> -->

</template>

<script setup>
import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { getNav } from '../api/apis'
import { useRouter } from 'vue-router';
import { NavId } from '../store/store'

const Navs = ref([]);
const getNavs = async () => {
    try {
        const result = await getNav();
        Navs.value = result.data;
        // console.log(Navs.value)
    } catch (error) {
        console.error(error);
    }
};
const router = useRouter();
const to = (item) => {
    // console.log("页脚输出：",item)
    // 跳转页面
        setNavIDName(item)
        router.push({ name: item.routerName });
}
const setNavIDName = (info) => {
  if (info != null) {
    NavId.setParentID(info.belongs)
    NavId.setID(info.id)
    NavId.setName(info.label)
  }
}
const isMobile = ref(window.innerWidth <= 768);
// 窗口尺寸
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  if (isMobile.value) {
    // console.log("现在是手机")
    // activePanel.value = null;
    // panelVisible.value = false;
  }
};
onBeforeMount(() => {
    getNavs();
})
onMounted(()=>{
    window.addEventListener('resize', handleResize);
})
</script>

<style lang="scss" scoped>
.box {
    background-color: rgba(34, 34, 34, 1);
    color: aliceblue;
    text-align: left;
    padding:  0 0 10px 0;
    .content{
        border-bottom: 1px rgba(167, 167, 167,1) solid; 
    }
    .left{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .right {
        width: 100%;
        display: flex;
        justify-content: space-between;

        // align-items: center;
        // justify-items: center;
        .onetype {
            margin: 20px 15px;
            text-align: left !important;

            .label {
                font-size: 1.2em;
                font-weight: 500;
                margin: 20px 0;
                text-align: left !important;
            }

            .item {
                font-size: 0.8em;
                margin: 10px 0;
                color: rgba(167, 167, 167,1);
                cursor: pointer;
            }
            .item:hover{
                color: rgb(209, 209, 209);
            }
        }

    }

    .right>div {
        flex-grow: 1;
        // text-align: center;
        /* 可选，居中文本 */
    }
}

.footer-content{
    width: 100%;
    text-align: center;
    color: rgba(167, 167, 167,1);
    margin-top: 15px;
}
@media (max-width: 768px) {
    .left{
        margin-top: 20px;
    }

}
</style>